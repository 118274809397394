import React from 'react';
import './../App.css';
const estyle ={
  backgroundColor: "#000"
}
const Electronics = () => (
  <div id='wowow' className=' w-full' style={estyle} >
    
            <video className=' w-full' loop="true" autoPlay="true" autoPlay muted src="imu-front.mp4"></video>
            <video className=' w-full' loop="true" autoPlay="true" autoPlay muted src="star.mp4"></video>
            <video loop="true" autoPlay="true" autoPlay muted src="chain.mp4"></video>
            <img classname="w-full" src="addfun_november.jpg"/>
            <video className=' w-full' loop="true" autoPlay="true" autoPlay muted src="demopad.mp4"></video>
            
            {/* <video className=' w-full' loop="true" autoPlay="true" src="imu-back.mp4"></video> */}
            <div class="m-20"/>

            {/* <img src="79.svg"/> */}
            {/* <div class="m-20"/>
            <img src="4x4.svg"/>
            <img src="mcap.svg"/> */}





  </div>
);

export default Electronics;