
import './App.css';
import MarkdownSample from './MarkdownSample.md'
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Consulting from './pages/consulting.js'
import Electronics from './pages/electronics.js'
import Home from './pages/home.js'
const md ={
 a : `
  # hello there\n
  what could come next.\n
  ## maybe another header\n
`
} 
const samplemarkdown = `
# This is a markdown table
- list item 1
- list item 2
- list item 3
1. list item 4
1. list item 5
1. list item 6

$$
\\int_{2020}^{2024} f(x)dx
$$

$$\\Sigma z  = (x + y)_{2, 6 }^{3,10} $$
| S/N | Pet | Image |
|--|--|--|
| 1 | Cat | img |
| 2 | Dog |![A dog looking at you](aef)|



Lift($$L$$) can be determined by Lift Coefficient ($$C_L$$) like the following

`
const eq = `
EQ1:
$$
\\int_{2020}^{2024} f(x)dx 
$$
`
const eq2 = "EQ2: $$\\int_{n}^{n+1}f(n)+\\theta$$"
const bce1={
  backgroundColor: "#E1E1E1"
}

const MDEQ = ({ content }) => {
  return (
    <div class="prose">
    <Markdown
      children={content}
      remarkPlugins={[remarkMath]}
      rehypePlugins={[rehypeKatex]}
    />
    </div>
  );
};


function App() {
  return (
    <div className="App">
      <header className="App-header">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Electronics />} />
          <Route path="/consulting" element={<Consulting />} />
          <Route path="/augment" element={<Consulting />} />
      </Routes>
    </Router>


      </header>
    </div>



  );
}

export default App;
